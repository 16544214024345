import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DynamicFormService, } from '@ng-dynamic-forms/core';
import { ADDRESS, CYTY, EXT, PHONE, PRESS, STATE_SELECT, STRING_FIELD, ZIP } from '../../../../../../../@util/dynamic-form/dynamic-controlls';
import { MedicalService } from '../../../../../../@core/medical.service';
import { FormValueBuilder } from '../../../../../partial/forms/form-tamplate';
import { MedicalTreatingSourcesComponent } from '../../medical-treating-sources.component';
import { ActivatedRoute, Router } from '@angular/router';
import { map, startWith } from 'rxjs/operators';
import { CatalogItemType } from '../../../../../../@models/medical/catalog-item-view';
import { NavigationService } from "../../../../../../@core/navigation.service";
import { AddressService } from "../../../../../../@core/address.service";
import { State } from "../../../../../../test-new-stepper-form/test-form/on-changes";
import { Functions } from "../../../../../../../@util/functions";
import { DynamicFormGroupModel } from "../../../../../../../@util/ui-material/form-controls";
import { UserInfoService } from "../../../../../../@core/user-info.service";
import { MedicalRequestsComponent } from "../../medical-requests/medical-requests.component";
import { MedicalPaymentsComponent } from "../../medical-payments/medical-payments.component";
import { GlobalSaveService } from "../../../../../../@core/global-save.service";
import { PERMISSIONS } from 'src/app/@config/permissions';
export class HospitalFormComponent {
    constructor(medicalService, formService, addressService, userInfoService, globalSaveService, 
    // private dialogRef: MatDialogRef<HospitalFormComponent>,
    // @Inject(MAT_DIALOG_DATA) private data: { sslgId: string, mode: FormMode, id?: number }
    _parent, route, router, navService) {
        this.medicalService = medicalService;
        this.formService = formService;
        this.addressService = addressService;
        this.userInfoService = userInfoService;
        this.globalSaveService = globalSaveService;
        this._parent = _parent;
        this.route = route;
        this.router = router;
        this.navService = navService;
        this.title = 'New Hospital';
        this.PERMISSIONS = PERMISSIONS;
        this.formLayout = {
            phones: {
                element: {
                    container: 'row-mb',
                    control: 'form-row',
                }
            },
            contact_phone: {
                element: {
                    host: 'form-element form-element_x2'
                }
            },
            contact_press: {
                element: {
                    host: 'form-element'
                }
            },
            contact_ext: {
                element: {
                    host: 'form-element'
                }
            },
            contact_fax: {
                element: {
                    container: 'row-mb'
                }
            },
            contact_address: {
                element: {
                    container: 'row-mb'
                }
            },
            contact_address2: {
                element: {
                    container: 'row-mb'
                }
            },
            contact_cyti: {
                element: {
                    container: 'row-mb'
                }
            },
            state_zip: {
                element: {
                    control: 'form-row',
                    container: 'row-mb'
                }
            },
            contact_state: {
                element: {
                    host: 'form-element'
                }
            },
            contact_zip: {
                element: {
                    host: 'form-element'
                }
            }
        };
        this.formTemplate = {
            name: STRING_FIELD({ id: 'name', label: 'Name', hidden: true, editableFor: PERMISSIONS.LOWER_EXEC }),
            recipient: {
                recipientName: STRING_FIELD({
                    id: 'recipientName',
                    label: 'Person To Contact',
                    maxLength: 128,
                    required: true,
                    validators: { required: null },
                    editableFor: PERMISSIONS.LOWER_EXEC
                }),
            },
            contact: {
                phone: PHONE({ id: 'contact_phone', label: 'Phone', editableFor: PERMISSIONS.LOWER_EXEC }),
                press: PRESS({ id: 'contact_press', label: '"Press"', editableFor: PERMISSIONS.LOWER_EXEC }),
                ext: EXT({ id: 'contact_ext', label: 'Ext.', editableFor: PERMISSIONS.LOWER_EXEC }),
                fax: PHONE({ id: 'contact_fax', label: 'Business Fax', editableFor: PERMISSIONS.LOWER_EXEC }),
                street: ADDRESS({ id: 'contact_address', editableFor: PERMISSIONS.LOWER_EXEC }),
                street2: ADDRESS({ id: 'contact_address2', label: 'Address 2', editableFor: PERMISSIONS.LOWER_EXEC }),
                city: CYTY({ id: 'contact_cyti', editableFor: PERMISSIONS.LOWER_EXEC }),
                state: STATE_SELECT({ id: 'contact_state', editableFor: PERMISSIONS.LOWER_EXEC }),
                zip: ZIP({ id: 'contact_zip', editableFor: PERMISSIONS.LOWER_EXEC }),
            }
        };
        this.form = {
            name: this.formTemplate.name,
            recipientName: this.formTemplate.recipient.recipientName,
            contact: DynamicFormGroupModel({
                id: 'contact',
                group: [
                    DynamicFormGroupModel({
                        id: 'phones',
                        group: [
                            this.formTemplate.contact.phone,
                            this.formTemplate.contact.press,
                            this.formTemplate.contact.ext
                        ],
                        editableFor: PERMISSIONS.LOWER_EXEC
                    }),
                    this.formTemplate.contact.fax,
                    DynamicFormGroupModel({
                        id: 'state_zip',
                        group: [
                            this.formTemplate.contact.zip,
                            this.formTemplate.contact.state,
                        ],
                        editableFor: PERMISSIONS.LOWER_EXEC
                    }),
                    this.formTemplate.contact.city,
                    this.formTemplate.contact.street,
                    this.formTemplate.contact.street2,
                ],
                editableFor: PERMISSIONS.LOWER_EXEC
            }),
        };
        this.subscribed = true;
        this.state = State.before;
        this.allFormState = State.before;
        this.tabNavigationActive = 0;
        this.nameCtrl = new FormControl();
        this.names = [];
        this.formModel = FormValueBuilder.buildFormModel(this.form);
        this.formGroup = this.formService.createFormGroup(this.formModel);
        this.nameCtrl.setValidators(Validators.required);
        this.sub = navService.back().subscribe(j => this.closeDoctorForm());
    }
    ngOnDestroy() {
        this._parent.hideHeader = false;
        this.sub.unsubscribe();
    }
    _filterNames(value) {
        ////console.log(value);
        const filterValue = typeof value === 'string' ? value.toLowerCase() : value.name ? value.name.toLowerCase() : '';
        ////console.log(filterValue);
        return this.names.filter(state => state.name.toLowerCase().indexOf(filterValue) === 0);
    }
    displayFn(item) {
        return item ? item.name : undefined;
    }
    ngAfterViewInit() {
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // if(this.userInfoService.isStaff() || this.userInfoService.isSslgStaff()){
            this.navLinks = [
                {
                    id: 'info',
                    label: 'Info'
                },
                {
                    id: 'requests',
                    label: 'Requests & Submissions'
                },
            ];
            // }else{
            // 	this.navLinks = [
            // 		{
            // 			id: 'info',
            // 			label: 'Info'
            // 		}
            // 	];
            // }
            setTimeout(() => {
                this._parent.hideHeader = true;
            });
            this.sslgId = this.route.snapshot.parent.params['sslgId'];
            if (this.route.snapshot.url[1].path === 'new') {
                this.names = yield this.medicalService.getCatalog(CatalogItemType.HOSPITAL).toPromise();
                this.filteredNames = this.nameCtrl.valueChanges
                    .pipe(map(value => {
                    this.hospital = null;
                    this.formTemplate.name.valueUpdates.next(this.nameCtrl.value + '');
                    if (value.id) {
                        this.subscription.unsubscribe();
                        if (value.id != -1) {
                            this.formGroup.disable({ onlySelf: true });
                            setTimeout(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                                this.hospital = yield this.medicalService.getCatalogItem(CatalogItemType.HOSPITAL, value.id).toPromise();
                                yield this.setFormData();
                            }));
                        }
                        else {
                            this.addressService.getPlaceInfo(value.placeId).subscribe(j => {
                                this.formTemplate.name.autocompleteSelectedValue = null;
                                if (j.zip)
                                    this.formTemplate.contact.zip.valueUpdates.next(j.zip);
                                if (j.state)
                                    this.formTemplate.contact.state.valueUpdates.next(j.state);
                                if (j.city)
                                    this.formTemplate.contact.city.valueUpdates.next(j.city);
                                if (j.locations)
                                    this.formTemplate.contact.street.valueUpdates.next((j.streetNumber ? j.streetNumber + ' ' : '') + (j.locations ? j.locations : ''));
                            });
                        }
                    }
                    else {
                        if (!this.subscribed) {
                            this.medicalService.setMedicalConditionsTemplate([]);
                            this.subscribeZipCode();
                        }
                        this.formGroup.enable({ onlySelf: true });
                        setTimeout(() => {
                            this.formGroup.reset();
                        });
                    }
                    return value;
                }), startWith(''), map((state) => {
                    this.onStateChanges();
                    if (state) {
                        let names = this._filterNames(state);
                        if (typeof state === 'string' && state.length > 3 || state.name) {
                            let query = typeof state === 'string' ? state : state.name;
                            this.addressService.findPlace(query, "HOSPITAL").pipe(map((place) => {
                                return place.map(j => {
                                    return Object.assign({ id: -1, name: j.title, label: j.description }, j);
                                });
                            })).subscribe(j => {
                                names.push(...j);
                                //console.log(this.filteredNames)
                            });
                        }
                        return names;
                    }
                    return this.names.slice();
                }));
                this.subscribeZipCode();
            }
            else {
                this.title = 'Info Hospital';
                try {
                    let result = yield this.medicalService.getCatalogItemInfo(this.sslgId, this.route.snapshot.paramMap.get('id')).toPromise();
                    this.hospital = result.catalog;
                }
                catch (e) {
                    this.router.navigate([this.route.snapshot.queryParams['returnUrl']], { queryParams: { page: this.route.snapshot.queryParams['page'] } });
                }
                // FormValueBuilder.setData(this.hospital.contact, this.contactTamplate);
                // setTimeout(() => {
                //     this.nameCtrl.patchValue(this.hospital.name);
                // });
                yield this.setFormData();
                setTimeout(() => {
                    this.formTemplate.name.hidden = false;
                    if (!this.globalSaveService.getStatusTrue() || this.isClaimant()) {
                        //this.formGroup.disable({onlySelf: false});
                        //this.nameCtrl.disable({onlySelf: false});
                    }
                });
                if (this.hospital.createdByClaimant)
                    document.getElementById('name').classList.add('claimant-badge');
            }
            this.oldFormData = FormValueBuilder.getData(this.form);
            this.oldSourceNameData = this.nameCtrl.value ? this.nameCtrl.value : '';
        });
    }
    showBlock(i) {
        this.tabNavigationActive = i;
        document.querySelectorAll('.blocks-tab').forEach((el) => {
            el.style.display = 'none';
        });
        document.getElementById('block-tab-' + this.navLinks[this.tabNavigationActive].id).style.display = '';
    }
    blockTabActive(i) {
        if (i == this.tabNavigationActive) {
            return true;
        }
        return false;
    }
    isCreationPage() {
        if (this.route.snapshot.url[1].path === 'new') {
            return true;
        }
        return false;
    }
    subscribeZipCode() {
        this.subscription = this.formTemplate.contact.zip.valueUpdates.subscribe((j) => {
            if (j && j.length == 5) {
                this.addressService.findAddress(j).subscribe(addr => {
                    if (addr && addr.state) {
                        this.formTemplate.contact.state.valueUpdates.next(addr.state);
                        this.formTemplate.contact.city.valueUpdates.next(addr.city);
                    }
                });
            }
            this.subscribed = true;
        });
    }
    showName() {
        // if(this.globalSaveService.getStatusTrue() && !this.isClaimant()){
        // 	return true;
        // }
        // return this.route.snapshot.url[1].path === 'new'
        if (!this.globalSaveService.getStatusTrue() && !this.isClaimant()) {
            return false;
        }
        return true;
    }
    showBtn() {
        if (!this.globalSaveService.getStatusTrue() && !this.isClaimant()) {
            return false;
        }
        return true;
    }
    setFormData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            setTimeout(() => {
                if (this.hospital !== null) {
                    try {
                        let street = this.hospital.contact.street ? this.hospital.contact.street : '';
                        this.hospital.contact.street = street.replace(/,/g, '');
                        const name = this.hospital.name ? this.hospital.name : '';
                        const fax = (this.hospital.contact && this.hospital.contact.fax) ? this.hospital.contact.fax : '';
                        this.hospitalContacts = {
                            name,
                            fax
                        };
                    }
                    catch (e) {
                    }
                    FormValueBuilder.setData(this.hospital, this.formTemplate);
                }
            });
        });
    }
    onBlur($event) {
    }
    onChange($event) {
    }
    onFocus($event) {
    }
    closeDoctorForm() {
        this.router.navigate([this.route.snapshot.queryParams['returnUrl']], { queryParams: { page: this.route.snapshot.queryParams['page'] } });
        // this.dialogRef.close();
    }
    saveDoctor(cmd) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            FormValueBuilder.setControlsToched(this.formGroup);
            //if (this.nameCtrl.value) {
            //this.formTemplate.name = this.nameCtrl.value.name ? this.nameCtrl.value.name : this.nameCtrl.value;
            const data = FormValueBuilder.getData(this.formTemplate);
            if (!this.hospital) {
                data.name = typeof this.nameCtrl.value === 'string' ? this.nameCtrl.value : this.nameCtrl.value.name;
                data.googlePlaceId = this.nameCtrl.value.placeId ? this.nameCtrl.value.placeId : null;
            }
            data.sslgId = this.sslgId;
            //if (this.route.snapshot.url[1].path !== 'new') {
            data.id = +this.route.snapshot.paramMap.get('id');
            //}
            //console.log(data)
            const doc = yield this.medicalService.saveTreatingSourcesHospital(this.sslgId, data, this.hospital);
            if (doc) {
                if (cmd == 'new') {
                    this.state = State.before;
                    this.router.navigate([this.route.snapshot.queryParams['returnUrl']], { queryParams: { page: this.route.snapshot.queryParams['page'] } });
                }
                else {
                    this.state = State.before;
                    this.router.navigate(['/claimants/medical/' + this.sslgId + '/visit-summaries/new'], { queryParams: {
                            returnUrl: this.route.snapshot.queryParams['returnUrl'],
                            catalogItemId: doc.id,
                            type: 'HOSPITAL',
                        } });
                }
            }
            //}
        });
    }
    onStateChanges() {
        const dataNew = FormValueBuilder.getData(this.form);
        const sourceNew = this.nameCtrl.value ? this.nameCtrl.value : '';
        if (this.oldFormData != '' && this.oldSourceNameData != '') {
            const isChanges = Functions.equals(this.oldFormData, dataNew);
            const isChangesSource = Functions.equals(this.oldSourceNameData, sourceNew);
            if (!isChanges || !isChangesSource) {
                this.state = State.after;
            }
            else {
                this.state = State.before;
            }
        }
    }
    isInputDirty() {
        return this.state == State.after;
    }
    addVisit() {
        this.saveDoctor('addVisit');
    }
    isClaimant() {
        return this.userInfoService.isClaimant();
    }
    getBtnTitle() {
        let title = 'Add a New Medical Source';
        if (this.route.snapshot.url[1].path !== 'new') {
            title = 'Update a Medical Source';
        }
        return title;
    }
    getBtnName() {
        if (this.route.snapshot.url[1].path !== 'new') {
            return 'Update';
        }
        return 'Add';
    }
    checkDisable() {
        if (!this.globalSaveService.isClient()) {
            return this.globalSaveService.checkDisabledFields(this.form);
        }
        return true;
    }
}
